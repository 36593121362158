.error-boundary {
  width: 100%;
  height: 60vh;
  @include flexbox;
  flex-direction: column;
  gap: 20px;
}

.retry-button {
  padding: 10px 40px;
}
