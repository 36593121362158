@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css');

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100% !important;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Pretendard Variable';
  background-color: color(gray, white);
  overflow-y: auto;
}

main {
  width: 100%;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
}
a {
  text-decoration: none;
}

input,
textarea {
  margin: 0;
  &::placeholder {
    font-family: 'Pretendard Variable';
    @include font(caption, md, color(grey, 05));
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

#root {
  height: 100%;
}

p {
  margin: 0;
  white-space: pre-wrap;
  cursor: default;
}

hr {
  border: none;
  border-bottom: 1px solid color(gray, 02);
}

input {
  border: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
