.toggle-button {
  @include flexbox;
  width: 446px;
  height: 48px;
  .button-wrapper {
    @include flexbox(start, center);
    flex: 1;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #c1c1cc;
    border-radius: 0px 8px 8px 0px;
    padding: 12px 16px;
    height: 50px;

    &.active {
      border-color: #4188f7;
      .contents {
        color: color(gray, black);
      }
    }

    &:first-child {
      border-width: 1px 1px 1px 1px;
      border-radius: 8px 0px 0px 8px;
    }

    .contents {
      margin-left: 8px;
      cursor: pointer;
      @include font(body, sm, color(gray, 06));
      &.active {
        color: color(gray, black);
      }
    }
  }
}
