.icon-wrapper {
  &.active {
    cursor: pointer;
  }
}

.pagination {
  @include flexbox;

  .previous-link,
  .next-link {
    a {
      .icon-wrapper {
        @include flexbox;
      }
    }
  }

  .previous-link {
    margin-right: 6px;
  }
  .next-link {
    margin-left: 6px;
  }
  .page-item {
    margin-left: 4px;
    margin-right: 4px;
    &.active-page {
      background: #000000;
      border-radius: 100%;
      .page-item-link {
        color: white;
      }
    }
    .page-item-link {
      cursor: pointer;
      width: 30px;
      height: 30px;
      @include flexbox;
      @include font(button, sm);
    }
  }
}
