.checkBoxForm {
  cursor: pointer;
  display: flex;
  align-items: center;

  .title {
    margin-left: 6px;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;

    &.bold {
      font-size: 17px;
      font-weight: 500;
      line-height: 26px;
    }
    &.bold2 {
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
    }
    &.thin {
      color: #3c3c44;
    }
    &.thin2 {
      color: #3c3c44;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
.view_button {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

  color: #82808f;
}
.view_button:hover {
  text-decoration: underline;
}
