.tooltip {
  position: absolute;
  left: -120px;
  top: -85px;
  width: 300px;
  border-radius: 8px;
  padding: 10px;
  background-color: rgb(151, 130, 128);
  box-sizing: border-box;
  white-space: pre-wrap;
  @include flexbox;
  @include font(caption, md);

  &.approve {
    background: #f5fdfa;
    border: 1px solid #31b787;
    color: #31b787;
  }

  &.reject {
    background: #fffafc;
    border: 1px solid #eb4c8f;
    color: #eb4c8f;
  }
}
