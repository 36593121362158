.sign-in {
  display: flex;
  height: 100vh;
  &__left {
    position: relative;
    background: linear-gradient(90.33deg, #e2abf5 0.05%, #b0aef9 33.71%, #9ac1f6 64.78%, #bbe0f4 99.48%);
    width: 100%;
    @include flexbox(end, center);

    .text-box {
      z-index: 2;
      position: absolute;
      margin-right: 20%;
      width: 447px;

      .pre-header {
        @include font(body, sm);
        margin: rem(27px) 0 rem(8px) 0;
      }
    }

    .background {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(253, 254, 254, 0.5) 15.43%,
        rgba(251, 252, 253, 0.8) 32.91%,
        #f8fafc 98.72%
      );
    }
  }

  &__right {
    input {
      width: rem(360px);
    }

    width: 100%;
    @include flexbox(start, center);

    &__content-box {
      @include flexbox;
      flex-direction: column;
      position: relative;
      margin-left: 20%;

      .page-header {
        @include font(heading, xl);
        width: 100%;
      }
      .header_description {
        margin-top: 4px;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #3c3c44;
      }

      hr {
        width: 100%;
        margin: rem(22px) 0 0 0;
      }

      .invalid-value {
        @include font(button, sm, color(alert, warning));
        position: absolute;
        top: 78px;
      }

      &__link-wrapper {
        @include flexbox;
      }

      .login-button {
        margin: rem(40px) 0 rem(20px) 0;
        cursor: pointer;
        width: 100%;
        height: 50px;
        border-radius: 25px;
      }

      &__link {
        cursor: pointer;
        @include font(button, sm, color(alert, info));
      }
      &__link:hover {
        text-decoration: underline;
      }

      .sign-in-input__wrapper {
        width: 100%;
        margin-top: rem(64px);
      }

      .sign-in-input__wrapper + .sign-in-input__wrapper {
        margin: rem(16px) 0 rem(16px) 0;
      }
      .cellphone_wrapper {
        margin-bottom: rem(40px);
      }
      .complete_title {
        //styleName: Heading/lg;
        font-family: Pretendard;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        width: rem(270px);
      }
      .complete_content {
        margin-top: rem(30px);
        margin-bottom: rem(30px);
        width: rem(310px);
        //styleName: Body/sm;
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        color: #3c3c44;
      }

      .complete_button {
        width: rem(320px);
        border-radius: 8px;
        padding: 14px 40px 14px 38px;
      }
      .mt {
        margin-top: 40px;
      }
    }

    .next_button {
      width: rem(360px);
      height: rem(48px);
      font-size: 17px;
      font-weight: 400;
      line-height: 26px;
      color: #ffffff;
    }
    .no_join_button {
      margin-top: rem(10px);
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #82808f;
    }
    .no_join_button:hover {
      text-decoration: underline;
    }

    .all_agree {
      margin-top: 40px;
      margin-bottom: 20px;
    }
    .row_sb {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .mr {
        margin-right: 10px;
      }
    }
  }

  .join_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #3c3c44;
  }
  .join_button {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
  }
  .join_button:hover {
    text-decoration: underline;
  }

  .form_border {
    cursor: pointer;
    margin-top: rem(20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e4e4e7;
    width: 360px;
    height: 84px;
    padding: 14px 16px 14px 16px;
    border-radius: 8px;
    gap: 10px;
    transition: background 0.1s ease-in-out; /* 배경 색상 전환 효과 */
    &.isAllChecked {
      background: #f6f6f9;
    }

    .description {
      margin-left: 22px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #3c3c44;
    }
  }

  .agree_form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: rem(360px);
    height: rem(360px);
  }
  .error {
    margin-bottom: 20px;
    text-align: center;
    width: rem(250px);
    color: #dc2143;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .button_wrapper {
    display: flex;
    justify-content: space-between;
    width: rem(360px);

    .cancel_button {
      border: 1px solid #000000;
      border-radius: 8px;
      width: rem(120px);
      height: rem(48px);
      &.half {
        width: rem(174px);
      }
    }
    .next_button {
      width: rem(220px);
      height: rem(48px);
      &.half {
        width: rem(174px);
      }
    }
  }
}
