.profile-wrapper {
  position: relative;

  .profile-button {
    @include flexbox;
  }
  .profile {
    position: absolute;
    top: 44px;
    left: 50%;
    width: rem(211px);
    transform: translate(-50%, 0%);
    padding: 24px;
    background: #ffffff;
    border-radius: 12px;
    filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.1));

    &__info {
      .item {
        &.email {
          @include font(body, md);
          margin-bottom: 5px;
        }

        &.wallet {
          @include font(caption, sm, color(gray, 05));
          padding-bottom: 4px;

          @include flexbox;
          .wallet-address {
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
          svg {
            margin-right: 7px;
          }
        }
      }
    }

    &__list {
      .item {
        // padding-bottom: 18px;
        &:first-child {
          //   padding-top: 8px;
        }

        &:last-child {
          .link {
            color: color(alert, warning);
            padding-bottom: 0px;
          }
        }

        .link {
          @include font(body, sm, color(gray, black));
          width: 100%;
          display: block;
          padding-bottom: 18px;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .profile-wrapper {
    .profile {
      transform: translate(-90%, 0%) !important;
    }
  }
}
