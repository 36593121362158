.tab {
  display: flex;
  flex-direction: column;
  .title {
    color: #161616;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
  .tab_element_wrapper {
    display: flex;
    margin-bottom: 24px;
    .tab_element {
      cursor: pointer;
      width: 130px;
      text-align: center;
      color: #d9d9d9;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      &.active {
        color: #161616;
        border-bottom: 2px solid #000000;
      }
      &.inactive {
        border-bottom: 2px solid #d9d9d9;
      }
    }
    .length {
      margin-left: 4px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #0655d0;
      padding: 1px 6px;
      width: 21px;
      height: 20px;
      border-radius: 8px;
      background: #ecf3ff;
    }
  }
}
