.sign-in-input {
  &__wrapper {
    position: relative;
    @include flexbox;
  }

  &__logo {
    position: absolute;
    left: 12px;
    top: 13px;
  }

  &__input {
    background: #eef2f7;
    border-radius: 8px;
    padding: rem(13px) rem(20px) rem(13px) rem(46px);
    width: 100%;
    @include font(body, sm, color(gray, 08));
    &::placeholder {
      @include font(button, sm);
      color: color(gray, 07);
    }
    &:focus {
      background-color: #ecf3ff;
      outline: none;
      // outline-color: none;
      // border-color: none;
    }
  }
}
