@mixin font($style, $size, $color: color(gray, black)) {
  @if $color {
    color: $color;
  }
  @if $style == title {
    @if $size == lg {
      font-size: rem(64px);
      line-height: rem(80px);
      font-weight: 700;
    }
    @if $size == md {
      font-size: rem(48px);
      line-height: rem(80px);
      font-weight: 700;
    }
  }
  @if $style == heading {
    @if $size == xl {
      font-size: rem(24px);
      line-height: rem(32px);
      font-weight: 600;
    }
    @if $size == lg {
      font-size: rem(24px);
      line-height: rem(38px);
      font-weight: 400;
    }
    @if $size == md {
      font-size: rem(18px);
      line-height: rem(26px);
      font-weight: 500;
    }
    @if $size == sm {
      font-size: rem(16px);
      line-height: rem(26px);
      font-weight: 500;
    }
  }
  @if $style == body {
    @if $size == xl {
      font-size: rem(20px);
      line-height: rem(24px);
      font-weight: 600;
    }
    @if $size == lg {
      font-size: rem(18px);
      line-height: rem(30px);
      font-weight: 400;
    }
    @if $size == md {
      font-size: rem(17px);
      line-height: rem(26px);
      font-weight: 400;
    }
    @if $size == sm {
      font-size: rem(16px);
      line-height: rem(24px);
      font-weight: 400;
    }
  }
  @if $style == caption {
    font-size: rem(14px);
    line-height: rem(20px);
    font-weight: 400;
  }
  @if $style == button {
    @if $size == xl {
      font-size: rem(20px);
      line-height: rem(24px);
      font-weight: 600;
    }
    @if $size == lg {
      font-size: rem(18px);
      line-height: rem(24px);
      font-weight: 500;
    }
    @if $size == md {
      font-size: rem(17px);
      line-height: 26px;
      font-weight: 400;
    }
    @if $size == sm {
      font-size: rem(15px);
      line-height: rem(28px);
      font-weight: 400;
    }
  }
}
