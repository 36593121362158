.token-option {
  background: #ffffff;
  border: 1px solid #e4e4e7;
  border-radius: 12px;
  padding: 24px;
  margin-top: 16px;
  .header-wrapper {
    @include flexbox(start, center);

    .header {
      @include font(heading, md);
      margin-right: rem(14px);
    }
  }

  .buttons {
    @include flexbox(between, center);
    margin-top: 24px;

    .token-option__button-wrapper {
      gap: 6px;
      position: relative;
      @include flexbox(between, center);

      .tooltip {
        top: 45px;
        width: 220px;
        padding: 14px 16px;
        box-sizing: border-box;
        background: #696974;
        @include font(caption, md, color(gray, white));
        display: none;
        left: 50%;
        transform: translate(-50%, 0%);
        opacity: 0.8;

        &.stop {
          width: 240px;
        }
      }

      .action-button {
        @include flexbox;
        @include font(button, sm);
        background: color(gray, 01);
        width: 150px;
        height: 36px;
        border-radius: 6px;
        transition: 0s;

        .startIcon {
          margin-right: 5px;
          &:hover {
            background-image: none;
          }
        }

        &:hover {
          border: 1px solid #93939d;
          .tooltip {
            display: block;
          }
        }
      }

      .list-button {
        @include flexbox;
        background: #f6f6f9;
        width: 40px;
        height: 36px;
        margin-top: 0;

        &:hover {
          border: 1px solid #93939d;
        }

        .startIcon {
          margin: 0;
        }
      }
    }
  }
}
