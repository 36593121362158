.confirm-modal {
  background: #ffffff;
  box-shadow: 0px 6px 24px -9px rgba(108, 125, 169, 0.4);
  border-radius: 16px;
  position: relative;
  @include flexbox;
  flex-direction: column;
  padding: rem(50px) rem(96px) rem(36px) rem(96px);

  .close-button {
    position: absolute;
    right: 28px;
    top: 28px;
    cursor: pointer;
  }
  .header {
    @include font(heading, md);
    margin-bottom: rem(18px);
  }
  .contents {
    @include flexbox;
    @include font(body, sm, color(gray, 07));
    white-space: pre-wrap;
    text-align: center;
    margin-bottom: rem(38px);
  }

  .children {
    @include flexbox;
    gap: 14px;
  }
}

.modal-button {
  width: 120px;
  height: 38px;
}
