.not-found {
  height: 100%;
  width: 100%;
  @include flexbox;
  flex-direction: column;
  @include font(heading, xl, color(gray, 05));

  .code-wrapper {
    margin-bottom: 40px;
  }
}
