.stock-info-container {
  flex: 1.3;
}

.stock-info {
  background: #ffffff;
  border: 1px solid #e4e4e7;
  border-radius: 12px;
  max-width: rem(698px);
  height: rem(356px);

  &.real-estate {
    height: rem(387px);
  }
  .page-info {
    @include flexbox(start, center);
    padding: rem(18px) rem(23px);
    height: 66px;
    .stock-type {
      margin-left: rem(16px);
      @include font(body, sm, color(gray, 06));
      @include flexbox;
      .type-symbol {
        margin-right: rem(8px);
      }
    }
  }

  .info-box {
    padding: rem(24px) rem(23px);
    @include flexbox(start, start);

    .info {
      width: 100%;
      .name-wrapper {
        @include flexbox(start, center);
        margin-bottom: rem(22px);
        height: 36px;
        .name {
          @include font(heading, xl);
          margin-right: rem(10px);
          @include ellipse;
        }
      }

      .meta-data {
        margin-top: 22px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;

        &.real-estate {
          margin-top: 0;
          gap: 10px 55px;

          .stock-info-input {
            .label {
              width: 94px;
            }
            .input {
              max-width: 100%;
              width: 70%;
            }
            .value {
              max-width: 280px;
            }
          }
        }
      }
    }
  }
  &__button-wrapper {
    @include flexbox(end, center);
    gap: 16px;
    .cancel-button {
      margin-top: rem(28px);
      width: 158px;
      height: 48px;
      text-align: center;
      background: #e9e9f4;

      &:hover {
        background: #ddddef;
      }
    }
    .edit-button {
      margin-top: rem(28px);
      width: 158px;
      height: 48px;
      text-align: center;
    }
  }
}
