.input-toggle {
  @include flexbox(start, center);
  position: relative;

  &.stock {
    .label {
      width: 94px;
    }
    .input {
      width: 70%;
      max-width: 302px;
    }
  }
  .label {
    @include font(heading, sm, color(gray, 04));
    width: 74px;
    margin-right: 8px;
  }
  .value {
    @include font(body, sm);
    @include flexbox;
    max-width: 150px;
    .copy {
      width: 127px;
      text-decoration: underline;
      margin-right: 2px;
      cursor: pointer;
      &.fit-to-contents {
        max-width: 156px;
        margin-right: 8px;
        width: fit-content;
      }
    }
    span {
      @include ellipse;
      max-width: 100%;
    }
  }

  .input {
    background: #eef2f7;
    border-radius: 6px;
    padding: rem(6px) rem(10px);
    @include font(button, sm, color(gray, 08));
    max-width: 156px;
    line-height: rem(18px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:disabled {
      color: color(gray, 04);
    }
  }

  .upload-button {
    position: absolute;
    right: 20px;
    @include font(caption, sm);
    padding: 2px 8px;
  }
  .endIcon {
    @include flexbox;
    cursor: pointer;
  }

  //   select
  ul {
    width: 70%;
    // max-width: 156px;
    position: relative;
    .selectbox {
      @include flexbox(between, center);
      background: #eef2f7;
      border-radius: 6px;
      width: 100%;
      border-radius: 6px;
      padding: rem(6px) rem(10px);
      @include font(button, sm, color(gray, 08));
      //   max-width: 156px;
      line-height: rem(18px);
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:disabled {
        color: color(gray, 04);
      }
    }

    .select-items {
      width: 100%;
      position: absolute;
      z-index: 2;
      background: #ffffff;
      border: 1px solid #c1c1cc;
      border-radius: 8px;
      max-height: 136px;
      overflow-y: auto;

      .item {
        margin: 8px;
        padding: 6px;
        cursor: pointer;
        &:hover {
          background: #eef2f7;
          border-radius: 6px;
        }
      }
    }
  }
}
