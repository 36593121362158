.deploy-history-item {
  @include content;
  width: 100%;
  margin-top: 52px;

  .top-nav {
    @include flexbox('between', center);
    &__title {
      @include font(heading, md, color(gray, 05));
      .bold {
        color: color(gray, 08);
      }
    }

    &__buttons {
      .timestamp {
        @include font(caption, md, color(gray, 04));
      }

      .button {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #93939d;
        margin-left: 12px;
        text-decoration-line: underline;
      }
    }
  }

  .info-container {
    margin-top: rem(16px);
    display: flex;
    gap: rem(16px);
  }
}
