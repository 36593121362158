.token-request-list {
  background-color: white;
  @include flexbox;
  flex-direction: column;
  padding: 56px 51px;
  box-shadow: 0px 6px 24px -9px rgba(108, 125, 169, 0.4);
  border-radius: 16px;
  position: relative;
  min-width: 700px;
  max-width: 1022px;

  .close-icon {
    position: absolute;
    top: 28px;
    right: 28px;
  }

  .modal-header {
    @include font(heading, md);
    margin-bottom: 14px;
  }

  &__empty-list {
    padding: 40px 0;
  }

  &__table {
    width: 980px;
    margin: 20px 14px 42px 14px;
    @include flexbox;
    flex-direction: column;
    border: 1px solid color(gray, 02);
    border-radius: 12px 12px 12px 12px;

    .list-item {
      width: 100%;
      @include flexbox;
      border-bottom: 1px solid color(gray, 02);
      height: 10%;

      &:last-child {
        border-bottom: none;
      }

      &.header {
        @include flexbox;
        width: 100%;
        background: color(gray, 01);
        border-radius: 12px 12px 0px 0px;
        .item {
          &.reason {
            text-decoration: none;
          }
        }
      }

      .item {
        @include font(button, sm);
        text-align: center;
        flex: 1;
        padding-top: 16px;
        padding-bottom: 16px;

        &.checkbox {
          flex: 0.4;
          cursor: pointer;

          &.all {
            visibility: hidden;
          }
        }

        &.number {
          flex: 0.4;
        }

        &.type,
        &.range {
          flex: 0.7;
        }

        &.wallet {
          flex: 1.2;
        }

        &.amount {
          flex: 0.7;
        }

        &.reason {
          text-decoration: underline;
          position: relative;

          .tooltip {
            top: 50px;
            padding-right: 30px;
            @include helpBox;
            display: block;
            opacity: 1;
            z-index: 2;
            height: fit-content;
            .close-button {
              position: absolute;
              z-index: 10;
              right: 10px;
              top: 10px;
            }
          }
          &:hover {
            color: color(gray, 06);
          }
        }

        &.txHash {
          flex: 1.2;
          @include flexbox;
          span {
            text-decoration: underline;
          }
          .copy-button {
            @include flexbox;
            margin-left: 7px;
          }
        }
        &.stage {
          @include flexbox;
        }
      }
    }
  }

  &__pagination {
    margin-bottom: rem(48px);
  }

  &__buttons {
    @include flexbox;
    gap: 14px;
    .modal-button {
      @include flexbox;
    }
  }
}
