.footer-container {
  border-top: 0.1px solid #e4e4e7;
  width: 100%;
  @include flexbox;
  flex-direction: column;
  margin-top: 200px;

  &.footer__home {
    border-top: 0.1px solid #3b3d44;
    margin-top: 0;
  }

  &.deploy-footer {
    margin-top: 0;
  }
}
.footer {
  width: 100%;
  max-width: 1260px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  padding: 41px 56px 29px 56px;
  .footer_content_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer_content_right {
    @include flexbox(center, start);
    .list {
      @include flexbox(start, start);
      gap: 6px;
      flex-direction: column;
      margin-right: 56px;

      &:last-child {
        margin-right: 0;
      }
      .list-title {
        @include font(caption, md);
        margin-bottom: 2px;

        &.list-title__home {
          color: color(gray, 02);
        }
      }

      .link {
        cursor: pointer;
        span {
          cursor: pointer;
        }
      }

      .item-label {
        @include font(caption, md, color(gray, 06));
        cursor: default;
      }
    }
    .menu {
      display: flex;
      justify-content: space-evenly;
    }
  }

  .logo {
    margin-bottom: 35px;
  }
  .font_md {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #c1c1cc;
  }
  .font_md2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #93939d;
    margin-top: 30px;
    margin-right: 50px;
  }
  .font_sm {
    min-width: 75px;
    margin-right: 50px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #696974;
  }
}
