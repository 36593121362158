.backdrop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  @include flexbox;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);

  &.transparent {
    background-color: transparent;
  }
}
