.sidebar {
  @include flexbox(center, start);
  height: 100%;
  min-width: 250px;
  .mt38 {
    margin-top: 38px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;

    .menu2_wrapper {
      display: flex;
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 26px;
    }

    .menu-wrapper {
      margin-left: 24px;
    }
    .menu_button_wrapper {
      display: flex;
      justify-content: center;
    }
    .mt12 {
      margin-top: 12px;
    }
    .arrow_Right {
      margin-top: 7px;
      margin-right: 8px;
    }
    .ml52 {
      margin-left: 52px;
    }
    .ml15 {
      margin-left: 15px;
    }
    .mb20 {
      margin-bottom: 20px;
    }
    .mb16 {
      margin-bottom: 16px;
    }
    .ml16 {
      margin-left: 16px;
    }
    .ml20 {
      margin-left: 20px;
    }
    .mr16 {
      margin-right: 16px;
    }
    .ml30 {
      margin-left: 30px;
    }
    .mr50 {
      margin-right: 50px;
    }
    .ml80 {
      margin-left: 80px;
    }
    .mb12 {
      margin-bottom: 12px;
    }
    .font {
      color: #161616;
      line-height: 26px;
      &-lg {
        font-weight: 500;
        font-size: 18px;
      }
      &-md {
        font-weight: 400;
        font-size: 17px;
      }
      &-sm {
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        color: #82808f;
      }
    }
    .align-center {
      display: flex;
      align-items: center;
    }
  }
}
