.layout {
  @include flexbox(between, center);
  flex-direction: column;
  overflow-y: auto;
  min-height: calc(100% - 80px);
  &.grey {
    background: #f6f6f9;
  }
  &.black {
    background: #0d0c11;
  }
  &.white {
    background: white;
  }
}
