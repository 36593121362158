.skeleton-box {
  display: inline-block;
  height: 100%; // 부모 요소와 같게
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  border-radius: 8px;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%); // 완전히 왼쪽에서 시작
    // 수평 그라데이션
    // 0~20%, 20%~60%, 60%~100% 각 구간 에서 색이 자연스레 바뀜.
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0) 100%
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  // 2초에 걸쳐 도형을 겹치게 우측으로 이동
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
