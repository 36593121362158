.button-wrapper {
  margin: 0;
  border: none;
  cursor: pointer;
  @include flexbox;
  @include font(button, md);
  color: color(gray, 07);
  transition: 0.4s;

  &.black {
    background: color(gray, black);
    border-radius: 8px;
    color: color(gray, white);
    &:hover {
      background: color(gray, 07);
    }
  }

  &.grey {
    background: #ddddef;
    border-radius: 8px;
  }

  &.outline {
    background: #ffffff;
    border: 1px solid #93939d;
    border-radius: 8px;
    color: color(gray, 07);

    &:hover {
      background: #f6f6f9;
    }
  }

  &.small {
    width: 120px;
    height: 38px;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  .startIcon {
    @include flexbox;
    margin-right: 10px;
  }

  .endIcon {
    @include flexbox;
    margin-left: 10px;
  }
}
