.image-edit-modal-wrapper {
  @include flexbox;
  flex-direction: column;

  .buttons {
    margin-top: 46px;
    @include flexbox;
    gap: 14px;
  }
}
