.home_content {
  .main_button_wrapper {
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: default;
    .main_title {
      font-weight: 700;
      font-size: 54px;
      line-height: 70px;
      text-align: center;
      color: #ffffff;
      &.gradation {
        background: linear-gradient(to right, #6be4ff 0%, #c361fb 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .description {
    width: 568px;
    margin-top: 32px;
    display: flex;
    justify-content: space-evenly;
    color: #e4e4e7;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
  .main_button {
    margin-top: 58px;
    width: 216px;
    height: 48px;
    background: #4188f7;
    border-radius: 24px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
}
