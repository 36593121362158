.form-token-delete {
  background-color: white;
  @include flexbox;
  flex-direction: column;
  padding: 56px 51px;
  box-shadow: 0px 6px 24px -9px rgba(108, 125, 169, 0.4);
  border-radius: 16px;
  position: relative;

  .close-icon {
    position: absolute;
    top: 28px;
    right: 28px;
  }

  .modal-header {
    @include font(heading, md);
  }

  .toggle-buttons {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .modal-pre-header {
    margin-top: 16px;
    @include font(body, sm, color(gray, 07));
    text-align: center;
  }
  .wallet-address {
    &__wrapper {
      margin-top: 20px;
      width: 100%;
      position: relative;
      .header-wrapper {
        @include flexbox(start, center);
        margin-bottom: 8px;
        .header {
          @include font(heading, sm);
        }
      }
    }

    &__input {
      width: 100%;
      @include font(body, sm);
      padding: 11px 14px 11px 14px;
      border: 1px solid #c1c1cc;
      border-radius: 8px;
      height: 42px;
    }

    &__button {
      width: 83px;
      height: 34px;
      position: absolute;
      border: 1px solid #c1c1cc;
      top: 38px;
      right: 7px;
      @include font(button, sm);
    }
  }

  .delete-amount {
    &__wrapper {
      margin-top: 20px;
      width: 100%;
      position: relative;
      .header-wrapper {
        @include flexbox(between, center);
        margin-bottom: 8px;
        .header {
          @include font(heading, sm);
        }
        .value-box {
          @include font(caption, md, color(primary, 01));
        }
      }
    }

    &__input {
      width: 100%;
      @include font(body, sm);
      padding: 11px 14px 11px 14px;
      border: 1px solid #c1c1cc;
      border-radius: 8px;
      height: 42px;
    }
  }

  .reason-textarea {
    width: 100%;
    margin-top: 24px;
    .header {
      @include font(heading, sm);
      margin-bottom: 8px;
    }
    &__textarea {
      width: 100%;
      height: 120px;
      border: 1px solid #c1c1cc;
      @include font(body, sm);
      border-radius: 8px;
      resize: none;
      padding: 8px 14px;
    }
  }

  .submit-wrapper {
    @include flexbox;
    margin-top: 42px;
    gap: 14px;

    .close-button {
      width: 120px;
      height: 38px;
    }

    .submit-button {
      width: 120px;
      height: 38px;
    }
  }
}
