.form {
  .input_group {
    position: relative;
    display: flex;
    .input_button {
      position: absolute;
      right: 4px;
      bottom: 2px;
      border: 1px solid #c1c1cc;
      padding: 8px 16px;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      border-radius: 6px;
      gap: 10px;
    }
  }
  .title-wrapper {
    margin-top: rem(25px);
    margin-bottom: rem(8px);
    display: flex;
    justify-content: space-between;
    &.mt {
      margin-top: rem(20px);
    }
    .wrapper {
      display: flex;
      align-items: center;
    }
    .question_icon {
      margin-bottom: 2px;
      cursor: pointer;
    }
    .validation_error {
      color: #dc2143;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      float: right;
    }
    .tooltip {
      top: 25px;
      left: -20px;
      z-index: 3;
      &.gray {
        cursor: none;
        background: #696974;
        border-radius: 8px;
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  input,
  .selectbox,
  .upload {
    // margin-top: 8px;
    width: 390px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #c1c1cc;
    border-radius: 8px;
    padding: 11px 0 11px 14px;
  }
  input,
  .selectbox,
  .upload:focus {
    outline-color: #696974;
    // border-color: #696974;
  }
  .selectbox {
    color: #82808f;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    .dropdown {
      float: right;
      margin-right: 10px;
      margin-top: 5px;
    }

    .selected {
      color: #3c3c44;
    }

    .disabled {
      background: #f6f6f9;
      color: #000000;
    }
  }

  .select-items {
    z-index: 2;
    position: absolute;
    width: 360px;
    background: #ffffff;
    border: 1px solid #c1c1cc;
    border-radius: 8px;
    padding: 5px 0px;

    &.cellphone {
      width: rem(115px);
      bottom: -30px;
      left: 0px;
    }
    .item {
      padding: 0px 14px;
      display: flex;
      align-items: center;

      height: 36px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #3c3c44;

      &.cellphone {
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        justify-content: center;
        align-items: flex-start;
      }
    }

    .item:hover {
      cursor: pointer;
      color: #000000;
      background: #f8f8fc;
      border-radius: 6px;
    }
  }

  .upload {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .custom_upload {
      cursor: pointer;
      margin-right: 4px;
      height: 34px;
      border: 1px solid #c1c1cc;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      padding: 8px 22px;
      border-radius: 6px;
    }
  }

  .upload.after_upload {
    justify-content: space-between;
  }

  .upload > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input:disabled {
    background: #f6f6f9;
    color: #000000;
  }
  input[readonly] {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }
  input[type='file'] {
    display: none;
  }
  .validation {
    border: 1px solid #dc2143;
  }
  .validation:focus {
    outline-color: #dc2143;
    border-color: #dc2143;
  }
}

.cellphone {
  display: flex;
  flex-direction: column;
  .telecom_selectbox {
    position: relative;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    width: rem(88px);
    height: rem(40px);
    border: 1px solid #c1c1cc;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .certification_form {
    margin-top: rem(5px);
  }
}
