.stage-label {
  @include flexbox;
  height: 30px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #696974;

  &.REVIEW,
  &.ING {
    color: #4188f7;
  }

  &.APPROVAL {
    color: #31b787;
  }

  &.REFUSE {
    color: #eb4c8f;
  }

  &.CANCEL {
    color: #696974;
  }

  &.background {
    width: 106px;
    &.REVIEW,
    &.ING {
      &.background {
        background: #f2edff;
        color: #724ae0;
      }
      color: #4188f7;
    }

    &.ETC {
      &.background {
        background: #ecf3ff;
        color: #0655d0;
      }
    }

    &.APPROVAL {
      &.background {
        background: #31b787;
      }
      color: #ecf3ff;
    }

    &.REFUSE {
      &.background {
        background: #eb4c8f;
      }
      color: #ecf3ff;
    }

    &.CANCEL {
      &.background {
        background: #e4e4e7;
      }
      color: #696974;
    }
  }

  &:hover {
    .tooltip-wrapper {
      display: block;
    }
  }

  .tooltip-wrapper {
    display: none;
    position: relative;
  }
}
