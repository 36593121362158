.navbar {
  display: flex;
  justify-content: center;
  z-index: 2;
  align-items: center;
  width: 100%;
  height: 80px;
  backdrop-filter: blur(5px);
  border-bottom: 1px solid #f1f1f1;
  background: #ffffff;
  &.navbar__home {
    border-bottom: 1px solid #323232;
    background: #0d0c11;
  }

  &__content {
    @include flexbox(between, center);
    @include content;
    padding: 0 rem(46px);
    .nav-list-wrapper {
      @include flexbox;
      .logo {
        margin-right: 60px;
        cursor: pointer;
      }

      .nav-list {
        @include flexbox(start, center);
        gap: 24px;
        &__link {
          @include font(button, sm, color(gray, 08));

          &.nav-list__link__home {
            color: color(gray, 03);
          }
        }
      }
    }
  }
  .sign-in__button {
    @include flexbox;
    padding: 6px 32px;
    background: color(gray, 02);
    border-radius: 8px;
    @include font(body, md, black);

    &.sign-in__home {
      background: color(gray, 07);
      color: color(gray, white);
    }
  }
}
