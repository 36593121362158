.token-status {
  @include flexbox(start, center);
  .status-box {
    @include flexbox(start, center);
    margin-left: 6px;
    gap: 6px;
    .label {
      @include font(caption, md, color(gray, 07));
    }
  }
}
