.deploy-history {
  @include content;

  .page-header {
    @include flexbox(between, end);
    margin: rem(48px) 0 rem(26px) 0;

    &__title {
      @include font(heading, xl);
    }
    &__button {
      @include font(button, md);
      @include flexbox;
      padding: 11px 24px;
    }
  }

  &__table-wrapper {
    background: color(gray, white);
    border: 1px solid color(gray, 02);
    border-radius: 12px;
    padding: rem(55px) rem(82px) rem(42px) rem(82px);
    min-height: 700px;
    .empty-list {
      @include flexbox;
      @include font(heading, sm, color(gray, 07));
    }

    .time-line {
      margin: 0 0 rem(54px) 0;
      width: 100%;
    }

    .table {
      width: 100%;
      @include flexbox;
      flex-direction: column;
      border: 1px solid color(gray, 02);
      border-radius: 12px 12px 12px 12px;
      margin-bottom: 42px;

      .list-item {
        width: 100%;
        @include flexbox;
        border-bottom: 1px solid color(gray, 02);
        height: 10%;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
        }
        &:last-child {
          border-bottom: none;
          &:hover {
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
            border-radius: 12px 12px 12px 12px;
          }
        }
        &.header {
          @include flexbox;
          width: 100%;
          background: color(gray, 01);
          border-radius: 12px 12px 0px 0px;
          .item {
            cursor: default;
            margin: 0;
            color: color(gray, 07);
          }
          &:hover {
            box-shadow: none;
          }
        }
        .item {
          cursor: pointer;
          @include font(button, sm);
          flex: 14;
          text-align: center;
          margin: 12px 0;

          &.image {
            @include flexbox;
            padding: rem(14px) 0;
            max-height: 62px;

            .image-wrapper {
              width: 64px;
              height: 64px;
              @include flexbox;
              border: 1px solid #e4e4e7;
              border-radius: 8px;
            }

            input {
              margin-right: 30px;
            }
          }

          &.name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
          }
          &.symbol {
            flex: 7;
          }

          &.stage {
            @include flexbox;
          }
        }
      }
    }
  }
}
