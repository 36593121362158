.review_status {
  @include flexbox;
  padding-bottom: rem(52px);

  &__item-wrapper {
    @include flexbox;
    flex: 1;

    .numbering {
      width: 30px;
      height: 30px;
      border: 1px solid #c1c1cc;
      border-radius: 100%;
      @include flexbox;
      margin: 10px;

      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }

      &.icon {
        border: none;
      }
    }

    &:last-child {
      hr {
        display: none;
      }
    }
  }

  &__hr {
    width: 10%;
  }
}
