.toast {
  width: fit-content;
  padding: 16px 60px;
  @include flexbox;
  background: #696974;
  opacity: 0.9;
  border-radius: 8px;
  position: fixed;
  z-index: 20;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);

  .contents {
    color: #ffffff;
  }
}
