.description {
  &__title-wrapper {
    @include flexbox(start, center);
    margin-top: rem(56px);
  }

  &__title {
    @include font(heading, md);
    margin-left: rem(8px)
  }

  &__body {
    @include font(body, sm, color(gray, 07));
    margin-top: rem(12px);
  }
}
