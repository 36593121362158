.page_layout {
  @include flexbox(center, start);
  @include content;
  height: 100%;
  &.deploy {
    padding: 0;
  }
  img {
    width: 100%;
  }
}
.deploy-container {
  display: flex;
  flex-direction: column;
  padding-top: 38px;
  padding-left: 40px;
  min-width: 932px;
  padding-bottom: 100px;
  border-left: 1px solid #f1f1f1;
}

.deploy-content {
  margin-top: 66px;

  .info {
    width: 454px;
    background: #ffffff;
    border: 1px solid #e4e4e7;
    border-radius: 12px;
    padding: 18px 32px 32px 32px;

    &.token {
      height: 884px;
    }
    &.platform {
      margin-left: 24px;
      height: 694px;
    }
    &.real-estate {
      height: 794px;
    }
    .line {
      transform: scaleY(0.1);
      position: relative;
      right: 32px;
      margin-top: 18px;
      border: 1px solid #e4e4e7;
      width: 454px;
      height: 0px;
    }
  }
}

.heading {
  &1 {
    color: #161616;
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }

  &2 {
    color: #161616;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  &3 {
    color: #161616;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  }
  &4 {
    color: #161616;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.deploy_button_form {
  margin-top: 24px;
  margin-left: 22px;
  @include flexbox();
  flex-direction: column;
  .button {
    width: 120px;
    height: 38px;
    &.cancel_button {
      border: 1px solid #93939d;
      border-radius: 6px;
    }
  }

  .fee_information {
    @include flexbox(between);
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #0655d0;
    width: 390px;
    height: 50px;
    background: #ecf3ff;
    border-radius: 8px;

    .left {
      @include flexbox();
      margin-left: 17px;
      span {
        margin-left: 8px;
      }
    }
    .right {
      margin-right: 20px;
    }
  }

  .button_form {
    margin-top: 16px;
    width: 390px;
    text-align: center;
    display: inline-block;
    @include flexbox();
    .save_button {
      width: 158px;
      height: 52px;
      padding: 14px 0px;
      gap: 6px;

      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #3c3c44;
      border: 1px solid #93939d;
      border-radius: 8px;
    }
    .apply_button {
      @include flexbox();
      color: #ffffff;
      margin-left: 16px;
      background: #000000;
      border-radius: 8px;
      width: 216px;
      height: 52px;
      padding: 14px 40px 14px 38px;
      gap: 6px;
    }
  }
}

.symbol_img_wrapper {
  margin-top: 8px;
  display: flex;
  .symbol_img {
    @include flexbox();
    width: 88px;
    height: 88px;
    border: 1px solid #c1c1cc;
    border-radius: 8px;
  }
  .symbol_img_contents {
    .content {
      .input_hide {
        display: none;
      }

      &.upload {
        display: inline-flex;
      }
      // @include flexbox(between);
      display: flex;
      justify-content: space-between;
      .title {
        color: #000000;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-left: 16px;
        margin-bottom: 16px;
        &.upload {
          margin-bottom: 8px;
        }
      }
      .description {
        margin-left: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #82808f;
      }
      .border_selector-wrapper {
        display: flex;
        height: 20px;

        input {
          // margin-right: 1rem;
          border: none;
          outline: none;
          padding: 0;
          -webkit-appearance: none;
          width: 34px;
          height: 20px;
          margin: 0;
          background-color: transparent;
          // cursor: pointer;
          &::-webkit-color-swatch-wrapper {
            padding: 0;
          }

          &::-webkit-color-swatch {
            border: 1px solid #c1c1cc;
            border-top-left-radius: 0.4rem;
            border-bottom-left-radius: 0.4rem;
          }
        }
        .color_picker-wrapper {
          cursor: pointer;
          @include flexbox();
          width: 16px;
          border: 1px solid #c1c1cc;
          border-top-right-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
          .color_picker {
          }
        }
      }
      .none_color {
        cursor: pointer;
        margin-top: 2px;
        margin-left: 10px;
        width: 16px;
        height: 16px;
      }
      .camera {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
