.token-info-container {
  flex: 2;
  .token-info {
    background: #ffffff;
    border: 1px solid #e4e4e7;
    border-radius: 12px;
    max-width: rem(698px);
    height: rem(356px);
    &.real-estate {
      height: rem(387px);
    }

    .page-info {
      @include flexbox(between, center);
      padding: rem(18px) rem(23px);
    }

    .info-box {
      @include flexbox(between, start);
      padding: rem(24px) rem(23px);

      .token-image-wrapper {
        position: relative;

        .token-image-edit-button-wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          @include flexbox(end, end);
          top: 0;
          &:hover {
            .token-image-edit-button {
              background: #e4e4e7;
            }
          }
        }

        &.clickable {
          cursor: pointer;
        }

        .image-box {
          background: #ffffff;
          border: 1px solid #e4e4e7;
          border-radius: 8px;
          width: 110px;
          height: 110px;
          @include flexbox;
          .image {
            width: 84px;
            height: 84px;
          }
        }
        .token-image-edit-button {
          @include flexbox;
          width: 28px;
          height: 28px;
          background: #f6f6f9;
          border: 1px solid #c1c1cc;
          border-radius: 6px;
          margin: 4px;
          transition: 0.4s;
        }
      }

      .info {
        width: 80%;
        margin-left: 28px;

        .name-wrapper {
          @include flexbox(start, center);

          .name {
            @include font(heading, xl);
            margin-right: rem(10px);
            @include ellipse;
          }
          .symbol {
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            color: #3c3c44;
          }
        }

        .meta-data {
          margin-top: 22px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px 20px;
          //   발행사 select
          .input-toggle {
            ul {
              width: 156px;
            }
          }
          &.edit {
            gap: 12px 26px;
          }
        }
      }
    }
  }

  .list-button {
    margin-top: rem(28px);
    width: 100px;
    height: 48px;
    background: #e9e9f4;

    &:hover {
      background: #ddddef;
    }
  }
}
