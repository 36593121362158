.admin-page {
  @include flexbox(center, start);
  @include content;

  &__contents {
    @include flexbox(start, start);
    align-items: center;
    flex-direction: column;
    padding: 48px 0% 48px 2%;

    &__header-wrapper {
      @include flexbox;
      width: 100%;
      justify-content: space-between;

      .header {
        margin-right: 16px;
        @include font(heading, xl);
      }
      .register_button {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        padding: 7px 32px;
        border: 1px solid #e4e4e7;
        border-radius: 4px;
      }
      .register_button:hover {
        animation: colorChange 0.3s ease-in-out forwards;
      }
      @keyframes colorChange {
        0% {
          background-color: transparent;
        }
        50% {
          background-color: #e4e4e7;
        }
        100% {
          background-color: #e4e4e7;
        }
      }

      span {
        @include font(body, sm, color(gray, 06));
      }

      .tab_wrapper {
        width: 240px;
        .tab {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          &_left {
            cursor: pointer;
            display: inline-block;
            width: 120px;
            text-align: center;
            padding: 10px 16px;
            border: 1px solid #e4e4e7;
            border-radius: 8px 0px 0px 8px;
          }
          &_right {
            cursor: pointer;
            display: inline-block;
            width: 120px;
            text-align: center;
            padding: 10px 16px;

            border: 1px solid #e4e4e7;
            border-width: 1px 1px 1px 0px;
            border-radius: 0px 8px 8px 0px;
          }
        }
        .active {
          color: #3c3c44;
        }
        .inactive {
          color: #c1c1cc;
          background: #f6f6f9;
        }
      }
    }

    .table {
      width: 100%;
      @include flexbox;

      flex-direction: column;
      border: 1px solid color(gray, 02);
      border-radius: 12px 12px 12px 12px;
      margin-bottom: 42px;
      margin-top: 20px;

      .list-item {
        width: 100%;
        @include flexbox;
        border-bottom: 1px solid color(gray, 02);
        height: 10%;

        &:last-child {
          border-bottom: none;
        }

        &.header {
          @include flexbox;
          width: 100%;
          background: color(gray, 01);
          border-radius: 12px 12px 0px 0px;
          .item {
            &.reason {
              text-decoration: none;
            }
          }
        }

        .item {
          @include font(button, sm);
          text-align: center;
          flex: 1;
          padding-top: 16px;
          padding-bottom: 16px;

          &.number {
            flex: 0.5;
          }

          &.type {
            flex: 0.8;
          }

          &.name {
            flex: 0.5;
            @include flexbox;
            p {
              @include ellipse;
              max-width: 50px;
            }
          }

          &.symbol {
            flex: 0.5;
            @include flexbox;
            p {
              @include ellipse;
              max-width: 50px;
            }
          }

          &.review-status {
            flex: 0.6;
            position: relative;

            .button {
              width: 100%;
              @include flexbox;
              gap: 6px;

              &:disabled {
                cursor: default;
              }

              .icon {
                &.on {
                  transform: rotate(180deg);
                }
              }
            }

            .dropdown {
              width: 100px;
              position: absolute;
              z-index: 3;
              background: #ffffff;
              border: 1px solid #c1c1cc;
              border-radius: 8px;
              @include flexbox;
              flex-direction: column;
              padding: 8px;
              button {
                padding: 4px 10px;
                @include font(button, sm);
                width: 100%;

                &:hover {
                  background: #eef2f7;
                  border-radius: 6px;
                }
              }
            }
          }

          &.reason {
            text-decoration: underline;
            position: relative;

            .tooltip {
              top: 50px;
              padding-right: 30px;
              @include helpBox;
              display: block;
              opacity: 1;
              z-index: 2;
              height: fit-content;
              .close-button {
                position: absolute;
                z-index: 10;
                right: 10px;
                top: 10px;
              }
            }
            &:hover {
              color: color(gray, 06);
            }
          }

          &.publish {
            flex: 0.7;
            &.disabled {
              cursor: default;
              color: color(gray, 03);
            }
          }
        }
      }
    }

    .pagination-wrapper {
      width: 100%;
      @include flexbox;
    }
  }
}

.stohublist {
  margin-top: 52px;
  margin-bottom: 42px;
  max-width: 1148px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 12px;

  .newscard {
    border-radius: 12px;
    border: 1px solid #e4e4e7;
    width: 272px;
    height: 321px;
    cursor: pointer;
    img {
      border-radius: 8px 8px 0 0;
      width: 100%;
      height: 182px;
    }
    .content {
      margin: 10px;
      display: flex;
      flex-direction: column;

      &__title {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      &__font1 {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #82808f;
      }
      &__content {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 48px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #161616;
      }
      .line {
        background: #d9d9d9;
        width: 248px;
        height: 1px;
      }
      &__footer {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      &__blue {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #0655d0;
      }

      &__red {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #dc2143;
      }
    }
  }
  .newscard:hover {
    animation: shadowChange 0.3s ease-in-out forwards;
  }
  @keyframes shadowChange {
    0% {
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0);
    }
    50% {
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.12);
    }
    100% {
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.24);
    }
  }
}

.confirm_modal {
  padding: 3.125rem 4rem 2.25rem 4rem;
}
.newsRegisterConfirmModalData {
  z-index: 20;
}
.news_register_modal {
  width: 542px;
  height: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.large {
    height: 580px;
  }
  .row_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    input {
      width: 260px;
    }
  }
  .upload_wrapper {
    width: 542px;
    .title {
      color: #161616;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
    }
    .upload_file_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 542px;
      height: 106px;
      border: 1px solid #c1c1cc;
      border-radius: 8px;
      .uplopad_file_description {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #82808f;
        margin-bottom: 8px;
      }
      .uplopad_file_button {
        cursor: pointer;
        padding: 10px 24px;
        background: #f6f6f9;
        border: 1px solid #e4e4e7;
        border-radius: 100px;
      }
      input[type='file'] {
        display: none;
      }
    }
  }
  .button_wrapper {
    margin-top: 56px;
    display: flex;
    .cancel_button {
      min-width: 120px;
      height: 38px;
      padding: 6px 34px;
      border: 1px solid #93939d;
      border-radius: 6px;
    }
    .cancel_button:hover {
      background: #f6f6f9;
    }
    .upload_button {
      margin-left: 14px;
      min-width: 120px;
      height: 38px;
      padding: 6px 34px;
    }
    .upload_button:hover {
      background: #3c3c44;
    }
  }
  input {
    width: 542px;
    border: 1px solid #c1c1cc;
  }
  .radio_button_wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    .radio_button {
      span {
        margin-left: 8px;
        margin-right: 18px;
      }
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .active {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #161616;
      }
      .inactive {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #82808f;
      }
    }
  }
}
