$flex-map: (
  start: flex-start,
  end: flex-end,
  between: space-between,
  around: space-around,
  stretch: stretch,
  center: center,
);

@function _get-flex-value($key) {
  @return map-get($flex-map, $key);
}

@mixin flexbox($js: center, $ai: center) {
  display: flex;
  align-items: _get-flex-value($ai);
  justify-content: _get-flex-value($js);
}

@mixin content {
  padding: 0 rem(56px) 0 rem(56px);
  width: 100%;
  max-width: 1260px;
}

@mixin mainContentModal__base {
  background-color: $primary__white;
  max-width: 900px;
  width: 100%;
  border-radius: rem(16px);
  padding: 3.6rem 3.2rem;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin backdrop {
  height: 100vh;
  width: 100%;
  background: rgba(51, 51, 51, 0.3);
  @include flex-center();
  position: absolute;
  top: 0;
  position: absolute;
  left: 0;
}

@mixin helpBox {
  position: absolute;
  background: #696974;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  display: none;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 0.8;
  text-align: center;
  white-space: pre-line;
  word-break: keep-all;
  text-align: center;
}

@mixin ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// animation
@mixin fadeIn-animation-mixin($name, $from, $to) {
  @keyframes #{$name} {
    0% {
      transform: translate3d($from, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d($to, 0, 0);
      opacity: 1;
    }
  }
}

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin fadeIn() {
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}
